import { cva } from 'class-variance-authority'

export { default as StatusIndicator } from './StatusIndicator.vue'

export const statusIndicatorVariants = cva(
  'flex items-center justify-center rounded-full text-xs ring-2 ring-white-950',
  {
    variants: {
      variant: {
        solid: 'text-white-950',
        tint: ''
      },
      size: {
        md: 'h-6 w-6',
        sm: 'h-5 w-5',
        xs: 'h-4 w-4',
        '2xs': 'h-3 w-3',
        '3xs': 'h-2 w-2'
      },
      color: {
        slate: '',
        cool: '',
        zinc: '',
        neutral: '',
        stone: '',
        red: '',
        orange: '',
        amber: '',
        yellow: '',
        lime: '',
        green: '',
        emerald: '',
        teal: '',
        cyan: '',
        sky: '',
        blue: '',
        indigo: '',
        violet: '',
        purple: '',
        fuchsia: '',
        pink: '',
        rose: ''
      }
    },
    compoundVariants: [
      { variant: 'solid', color: 'slate', class: 'bg-slate-600' },
      { variant: 'solid', color: 'cool', class: 'bg-cool-600' },
      { variant: 'solid', color: 'zinc', class: 'bg-zinc-600' },
      { variant: 'solid', color: 'neutral', class: 'bg-neutral-600' },
      { variant: 'solid', color: 'stone', class: 'bg-stone-600' },
      { variant: 'solid', color: 'red', class: 'bg-red-600' },
      { variant: 'solid', color: 'orange', class: 'bg-orange-600' },
      { variant: 'solid', color: 'amber', class: 'bg-amber-600' },
      { variant: 'solid', color: 'yellow', class: 'bg-yellow-600' },
      { variant: 'solid', color: 'lime', class: 'bg-lime-600' },
      { variant: 'solid', color: 'green', class: 'bg-green-600' },
      { variant: 'solid', color: 'emerald', class: 'bg-emerald-600' },
      { variant: 'solid', color: 'teal', class: 'bg-teal-600' },
      { variant: 'solid', color: 'cyan', class: 'bg-cyan-600' },
      { variant: 'solid', color: 'sky', class: 'bg-sky-600' },
      { variant: 'solid', color: 'blue', class: 'bg-blue-600' },
      { variant: 'solid', color: 'indigo', class: 'bg-indigo-600' },
      { variant: 'solid', color: 'violet', class: 'bg-violet-600' },
      { variant: 'solid', color: 'purple', class: 'bg-purple-600' },
      { variant: 'solid', color: 'fuchsia', class: 'bg-fuchsia-600' },
      { variant: 'solid', color: 'pink', class: 'bg-pink-600' },
      { variant: 'solid', color: 'rose', class: 'bg-rose-600' },
      { variant: 'tint', color: 'slate', class: 'bg-slate-200 text-slate-600' },
      { variant: 'tint', color: 'red', class: 'bg-red-200 text-red-600' },
      { variant: 'tint', color: 'yellow', class: 'bg-yellow-200 text-yellow-600' },
      { variant: 'tint', color: 'green', class: 'bg-green-200 text-green-600' },
      { variant: 'tint', color: 'blue', class: 'bg-blue-200 text-blue-600' }
    ],
    defaultVariants: {
      color: 'red',
      variant: 'solid',
      size: '2xs'
    }
  }
)
